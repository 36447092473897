import config from '../config/config'
import { axios } from './index'

const baseUrl = config.baseUrl + '/exam/api/v1'

export default {
  addExam(id, ids) {
    return axios.post(baseUrl + `/post/exam/add?postId=${id}`, ids)
  },
  delExam(id, ids) {
    return axios.post(baseUrl + `/post/exam/delete?postId=${id}`, ids)
  },
  editExam(ids) {
    return axios.post(baseUrl + '/exam/update', ids)
  },
  getExam(id, page, size) {
    return axios.get(baseUrl + `/post/exam/get?postId=${id}&page=${page}&size=${size}`)
  },
  getAddExam(id) {
    return axios.get(baseUrl + `/post/exam/get/can-add?postId=${id}`)
  },
  getAttendance(id, page, size) {
    return axios.get(baseUrl + `/user-exam/attendance?examId=${id}&page=${page}&size=${size}`)
  },
  getUserEvaluates(id, page, size) {
    return axios.get(baseUrl + `/post/exam/user/evaluates?examId=${id}&page=${page}&size=${size}`)
  }
}
