<template>
  <div class="examlist-layout">
    <div class="content-wrapper">
      <el-breadcrumb ref="breadcrumb" class="yt-breadcrumb">
        <el-breadcrumb-item>考试管理</el-breadcrumb-item>
        <el-breadcrumb-item>考试列表</el-breadcrumb-item>
      </el-breadcrumb>

      <div class="yt-option--style">
        <el-input size="small" v-model="searchObj.examName" placeholder="通过考试名称模糊搜索" style="width: 274px">
          <div slot="suffix" class="icon-suffix--style">
            <YTIcon :href="'#icon-sousuo'" />
          </div>
        </el-input>
        <el-button @click="createNewPaper" type="primary" class="yt-btn1" size="small">
          <div>
            <YTIcon @click="createNewPaper" :href="'#icon-xinzeng'" />
            <p>新建考试</p>
          </div>
        </el-button>
        <el-button class="el-button--small" @click="checkLength" size="small">
          <div>
            <YTIcon @click="checkLength" :href="'#icon-shanchu'" />
            <p>删除考试</p>
          </div>
        </el-button>
      </div>
    </div>
    <div class="yt-content">
      <Table
        class="yt-table"
        :columns="columns"
        :data="tableData"
        :loading="loading"
        height="calc(100% - 35px)"
        @on-selection-change="selectedChange"
      >
        <template slot-scope="{ row, index }" slot="total">
          <div>{{ row.total || '/' }}</div>
        </template>
        <template slot-scope="{ row, index }" slot="questionAmount">
          <div>{{ row.questionAmount || '/' }}</div>
        </template>
        <template slot-scope="{ row }" slot="status">
          <div>{{ row.status | getStatus }}</div>
        </template>
        <template slot-scope="{ row, index }" slot="level">
          <div class="nowrap">
            {{ row.level === 0 ? '简单' : row.level === 1 ? '普通' : '困难' }}
          </div>
        </template>
        <template slot-scope="{ row, index }" slot="condition">
          <div class="nowrap" :title="row.condition">{{ row.condition }}</div>
        </template>
        <template slot-scope="{ row, index }" slot="creatorName">
          <div>{{ row.creatorName }}</div>
        </template>
        <template slot-scope="{ row, index }" slot="createdTime">
          <span>{{ $formatTime(row.createdTime, 'yyyy-MM-dd') }}</span>
        </template>
        <template slot-scope="{ row, index }" slot="pt">
          <div class="row-action-container yt-flex-layout">
            <span class="tool-button" v-if="row.status === 4" @click="examRelease(row)">发布</span>
            <span class="tool-button" v-if="row.status === 2 || row.status === 1" @click="examSet(row, 0)">设置</span>
            <span class="tool-button" v-if="row.status === 2 || row.status === 1" @click="examSet(row, 1)">考题</span>
            <span class="tool-button" v-if="row.status === 4 || row.status === 3" @click="handleClickMenu('delete', row)">删除</span>
            <span class="tool-button" v-if="row.status === 3" @click="examReport(row)">查看结果</span>
            <div v-if="row.status !== 4 && row.status !== 3">
              <el-dropdown placement="bottom-start">
                <div class="tool-button">
                  更多
                </div>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item v-if="row.status === 2 || row.status === 1">
                    <span style="white-space: nowrap" @click="examSet(row, 2)">考生</span>
                  </el-dropdown-item>
                  <el-dropdown-item>
                    <span style="white-space: nowrap" @click="handleClickMenu('delete', row)">删除</span>
                  </el-dropdown-item>
                  <el-dropdown-item v-if="row.status === 2 || row.status === 1">
                    <span style="white-space: nowrap" @click="examReport(row)">查看结果</span>
                  </el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </template>
      </Table>
      <Page
        class="yt-page"
        :class="'yt-page-' + (total.toString().length > 1 ? total.toString().length : 2)"
        :total="total"
        :current="page + 1"
        :page-size-opts="[10, 20, 40, 100]"
        :page-size="size"
        show-elevator
        show-sizer
        show-total
        @on-change="changePage"
        @on-page-size-change="changeSize"
      />
    </div>
    <ExamSelectModal :is-add="false" v-model="modifyPaperBankModal" :paper-bank="paperBank" @on-success="handleOperationSuccess" />
    <DeleteModal
      class="delModal"
      v-model="deleteVisible"
      title="确认删除考试吗？"
      :loading="deleteLoading"
      @on-ok="deleteExam()"
      @on-cancel="deleteVisible = false"
    >
      <p>删除考试后不可恢复，你还要继续吗？</p>
    </DeleteModal>
    <el-dialog title="发布失败" width="400px" :visible.sync="setDialog" center>
      <div style="text-align: center;color: #333;font-size: 14px">请{{ postTitle }}后再重新发布考试</div>
      <div slot="footer">
        <el-button size="small" type="primary" @click="toSetExam(postTitle)">{{ postTitle }}</el-button>
        <el-button size="small" class="el-button--default" @click="setDialog = false">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import { getStatusByValue, EXAM_STATUS } from '@/util/examStatus'
import ExamSelectModal from '../exam/ExamSelectModal'
import paperAnswerDetail from '../paper/paperAnswerDetail'
import DeleteModal from '../../../common/DeleteModal'
import ConfirmDialog from '../../../common/dialog/ConfirmDialog'
import YTIcon from '../../../common/YTIcon'
import examApi from '@api/exam'
import newExamManageApi from '@api/newExamManage'
import { throttle } from '@util/throttle'
export default {
  components: { DeleteModal, paperAnswerDetail, ExamSelectModal, ConfirmDialog, YTIcon },
  data() {
    return {
      selectedExamId: [],
      loading: false,
      clickRow: null,
      deleteVisible: false,
      deleteLoading: false,
      postId: '',
      paperBank: {
        type: Object,
        required: true
      },
      modifyPaperBankModal: false,
      showRel: false,
      isSpread: false,
      searchObj: {
        examName: null,
        paperName: null,
        status: 0
      },
      courseIds: [],
      tableData: [],
      columns: [
        {
          type: 'selection',
          width: 60,
          align: 'center'
        },
        {
          title: '考试名称',
          key: 'name',
          minWidth: 135,
          align: 'left',
          ellipsis: true,
          tooltip: true,
          className: 'paper-column'
        },
        // {
        //   title: '试卷名称',
        //   key: 'paper',
        //   ellipsis: true,
        //   tooltip: true
        // },
        {
          title: '总分',
          slot: 'total',
          sortable: true
        },
        {
          title: '题量',
          slot: 'questionAmount'
        },
        {
          title: '考生人数',
          key: 'examineeNum',
          align: 'center'
        },
        {
          title: '提交人数',
          key: 'submitCount',
          align: 'center'
        },
        {
          title: '状态',
          slot: 'status',
          fixed: 'right'
        },
        {
          title: '创建时间',
          align: 'left',
          slot: 'createdTime',
          sortable: true
        },
        {
          title: '创建人',
          key: 'creatorName',
          sortable: true,
          tooltip: true,
          ellipsis: true
        },
        {
          title: '操作',
          fixed: 'right',
          slot: 'pt',
          className: 'opt-column'
        }
      ],
      setDialog: false,
      postTitle: '设置考题',
      page: 0,
      size: 10,
      total: 0
    }
  },
  created() {
    this.searchData(this.searchObj, 0, 10)
    this.$watch(
      'searchObj.examName',
      throttle(newQuery => {
        this.$emit('searchObj.examName', newQuery)
        this.searchData(this.searchObj, 0, 10)
      })
    )
  },
  computed: {
    EXAM_STATUS() {
      return EXAM_STATUS
    }
  },
  filters: {
    getStatus(val) {
      switch (val) {
        case 0:
          return '未知'
        case 1:
          return '未开始'
        case 2:
          return '进行中'
        case 3:
          return '已结束'
        case 4:
          return '未发布'
        case 9:
          return '已删除'
      }
    }
  },
  methods: {
    toSetExam(msg) {
      // 跳转指定页面设置考试
      this.setPaperQuestions({ examId: this.clickRow.examId, paperId: this.clickRow.paperId || null }, msg === '设置考题' ? 2 : 3)
    },
    examRelease(row) {
      // 发布考试
      this.clickRow = row
      newExamManageApi.postExam(row.examId).then(res => {
        if (res.code === 0) {
          this.$message.success('考试发布成功！')
          this.searchData(this.searchObj, 0, 10)
        }
        if (res.code === 7223 || res.code === 9019) {
          // 考试没有试卷 或 无参考人员
          this.postTitle = res.code === 7223 ? '设置考题' : '设置参考人员'
          this.setDialog = true
        }
      })
    },
    setPaperQuestions(row, index) {
      this.$router.push({
        path: '/exam/created/paper',
        query: {
          isSetPaperInfo: true,
          paperId: row.paperId,
          examId: row.examId,
          stepIndex: index
        }
      })
    },
    examSet(row, index) {
      // 编辑考试信息
      this.$router.push({
        path: '/exam/editor/exam',
        // path: '/exam/created/paper',
        query: {
          paperId: row.paperId,
          examId: row.examId,
          stepIndex: index
        }
      })
    },
    examReport(row) {
      this.$router.push({
        path: '/exam/result',
        query: {
          id: row.examId,
          examName: row.name,
          paperBankId: row.paperBankId,
          paperId: row.paperId,
          exam: true,
          name: row.name,
          IsExamLibrary: true
        }
      })
    },
    createNewPaper() {
      this.$router.push('/exam/created/paper')
    },
    checkLength() {
      if (this.selectedExamId.length > 0) {
        this.deleteVisible = true
      } else {
        this.$message.warning('请选择要删除的试卷')
      }
    },
    selectedChange(val) {
      this.selectedExamId = val.map(item => {
        return item.examId
      })
    },
    initTableData(page, size) {
      this.loading = true
      examApi
        .getExamList(page, size)
        .then(res => {
          this.tableData = res.res.data
          this.total = res.res.total
        })
        .finally(() => {
          this.loading = false
        })
    },
    searchData(data, page, size) {
      this.loading = true
      this.isSpread = false
      newExamManageApi.allExamList(page, size, data).then(res => {
        this.tableData = res.res.data
        this.total = res.res.total
        this.loading = false
      })
    },
    changePage(val) {
      this.page = val - 1
      this.searchData(this.searchObj, this.page, this.size)
    },
    changeSize(val) {
      this.page = 0
      this.size = val
      this.searchData(this.searchObj, this.page, this.size)
    },
    getStatus(status) {
      return getStatusByValue(status)
    },
    handleClickMenu(name, row) {
      switch (name) {
        case 'delete':
          this.handleDeleteExam(row)
          break
        case 'preview':
          this.handlePreviewExam(row)
          break
        default:
          break
      }
    },
    updateExam(row) {
      this.courseIds = []
      this.courseIds.push(row.courseId)
      this.showRel = false
    },
    handleOperationSuccess(name, res) {
      // this.$emit(name, res)
    },
    handlePreviewExam(row) {
      this.$router.push(`/manage/exam/detail/${row.examId}`)
    },
    handleDeleteExam(row) {
      this.selectedExamId = [row.examId]
      this.deleteVisible = true
    },
    deleteExam() {
      this.deleteLoading = true
      newExamManageApi
        .delExam(this.selectedExamId)
        .then(() => {
          this.$message.success('删除成功')
          this.selectedExamId = []
          this.searchData(this.searchObj, this.page, this.size)
          // this.initTableData(0, 10)
        })
        .finally(() => {
          this.deleteLoading = false
          this.deleteVisible = false
        })
    }
  }
}
</script>
<style lang="less" scoped>
/*@import '../../../../theme/variables';*/
@import '~@/theme/tableDropdownMenu';

.examlist-layout {
  padding: 15px;
  display: flex;
  height: 100%;
  flex-direction: column;
  overflow: auto;
  .func-bar {
    margin-top: 15px;
    margin-bottom: 20px;
    width: 100%;
    height: 50px;
    padding-left: 20px;
    position: relative;
    display: flex;

    .batchDele {
      width: 50%;
      height: 100%;
      text-align: left;
      display: flex;
      align-items: center;
    }
    .filter-btn {
      margin-right: 20px;
      min-width: 100px;
    }

    .search-container {
      width: 50%;
      float: right;
      margin-top: 10px;
      text-align: right;
      margin-right: 42px;
      .search {
        width: 60px;
      }
      .searchInput {
        width: 280px;
      }
      .searchInput ::v-deep .ivu-input {
        border: 0;
        display: inline-block;
        border-bottom: 1px solid #ccc;
        border-radius: 0;

        &:focus {
          box-shadow: 0 1px 0 rgba(45, 140, 240, 0.2);
        }
      }

      .advanced-container {
        display: inline-block;
        cursor: pointer;
        margin-left: 10px;
        font-size: 14px;
        padding-right: 20px;

        svg.icon {
          transition: all @default-transition;
          transform: rotate(0deg);
          margin-left: 8px;
        }

        svg.icon.spread {
          transform: rotate(180deg);
        }
      }

      .advanced-board {
        position: absolute;
        z-index: 100;
        top: 56px;
        right: 0;
        width: 390px;
        height: 0;
        overflow: hidden;
        border-right: solid 5px @primary-color;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        transition: all @default-transition;

        &.show {
          height: 250px;
        }

        .search {
          margin-right: 20px;
          height: 40px;
          border-radius: 4px;
        }

        .form {
          width: 330px;
          margin-top: 20px;
          ::v-deep .ivu-form-item-label {
            width: 60px !important;
          }
          ::v-deep .ivu-form-item-content {
            display: flex;
          }
          ::v-deep .ivu-input {
            width: 250px;
          }
        }
      }
    }
  }
  .table-container {
    width: 100%;
    overflow: auto;
    flex: 1 1 auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 10px;
    ::v-deep .ivu-table {
      height: inherit;
      .opt-column {
        height: 56px;
        .operation {
          cursor: pointer;
          color: @primary-color;
          transition: all @default-transition;

          &:hover {
            color: @primary-hover-color;
          }
        }
      }
    }
    ::v-deep .flex-table {
      border-bottom: 1px solid #e8eaec;

      .name-column {
        width: 20%;
      }
      .fileName-column {
        width: 30%;
      }
      .type-column {
        width: 15%;
      }
      .time-column {
        width: 15%;
        flex: 1 1 auto;
      }
      .ivu-table-body {
        cursor: pointer;
      }
    }

    .page {
      flex: 0 0 auto;
      padding: 20px;
      font-size: 14px;

      .page-options {
        float: left;
        height: 32px;
        line-height: 32px;
      }
    }
  }
  ::v-deep .ivu-table-overflowX {
  }
  .row-action-container * + * {
    /*margin-left: 10px;*/
  }
  ::v-deep .ivu-table-cell {
    white-space: inherit;
  }
}
.table-wrapper {
  padding: 0;
  ::v-deep .flex-table {
    width: 100%;
    .select-column {
      width: 35px;
    }
    .paper-name-column {
      width: 15%;
      flex: 1 1 auto;
    }
    .name-column {
      width: 10%;
    }
    .time-column {
      width: 9%;
    }
    .num-column {
      width: 7%;
    }
    .count-column {
      width: 8%;
    }
  }
}
.yt-option--style {
  margin-right: 10px;
  .yt-btn1 {
    color: #fff !important;
    margin-left: 10px;
  }
}
.icon-suffix--style {
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
}
.content-wrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}
::v-deep .el-dropdown {
  display: flex;
  align-items: center;
}
.tool-button {
  cursor: pointer;
  color: #448bff;
  font-size: 12px;
  &:hover {
    color: #07409d;
  }
  &:not(:last-child) {
    margin-right: 10px;
  }
}
.delModal {
  ::v-deep .confirm-header {
    margin-left: 0 !important;
    justify-content: center;
  }
  ::v-deep .content {
    margin: 20px 0 20px 0 !important;
  }
}
::v-deep .opt-column {
  height: 56px;
}
</style>
